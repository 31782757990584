var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      id: "sidebar-form-event-product",
      title: _vm.isEdit
        ? _vm.$t("Editar produto")
        : _vm.$t("Adicionar produto"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "600px",
    },
    on: { save: _vm.onSaveItem, hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              { ref: "itemSidebarForm", attrs: { name: "itemSidebarForm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("e-search-sku", {
                          attrs: {
                            id: "event_product_sidebar-sku_id",
                            "input-id": "event_product_sidebar-sku_product",
                            "value-is-object": "",
                            "store-id": _vm.storeId,
                            "price-table-id": _vm.priceTableId,
                            disabled: _vm.isEdit,
                            "default-options": _vm.defaultSkuOptions,
                            autofocus: "",
                          },
                          on: { input: _vm.onSelectProduct },
                          model: {
                            value: _vm.itemForm.sku,
                            callback: function ($$v) {
                              _vm.$set(_vm.itemForm, "sku", $$v)
                            },
                            expression: "itemForm.sku",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "5" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "event_product_sidebar-quantity",
                            type: "number",
                            disabled: _vm.isEdit,
                            label: _vm.$t("Quantidade reservada"),
                            validation: "required|min:1",
                          },
                          model: {
                            value: _vm.itemForm.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.itemForm, "quantity", _vm._n($$v))
                            },
                            expression: "itemForm.quantity",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.itemForm.id
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: { click: _vm.addSkuStock },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Adicionar reserva de estoque"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.itemForm.quantityAdded != null
                          ? _c(
                              "b-col",
                              { attrs: { md: "5" } },
                              [
                                _c("FormulateInput", {
                                  staticClass: "required mt-1",
                                  attrs: {
                                    id: "event_product_sidebar-quantityAdded",
                                    type: "number",
                                    label: _vm.$t("Quantidade a adicionar"),
                                    validation: "required|min:1",
                                  },
                                  model: {
                                    value: _vm.itemForm.quantityAdded,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.itemForm,
                                        "quantityAdded",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "itemForm.quantityAdded",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  { staticClass: "mt-1" },
                  [
                    _c("b-col", [
                      _c("div", [
                        _c("p", { staticClass: "h6 mb-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Quant. em estoque")) + " "
                          ),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "h4 text-bold",
                            class: { "text-danger": _vm.infoValues.stock < 0 },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.infoValues.stock === 0
                                    ? 0
                                    : _vm.infoValues.stock || "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }