<template>
  <e-sidebar
    id="sidebar-form-event-product"
    :title="isEdit ? $t('Editar produto') : $t('Adicionar produto')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="onSaveItem"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="itemSidebarForm"
        name="itemSidebarForm"
      >
        <b-row>
          <b-col>
            <e-search-sku
              id="event_product_sidebar-sku_id"
              v-model="itemForm.sku"
              input-id="event_product_sidebar-sku_product"
              value-is-object
              :store-id="storeId"
              :price-table-id="priceTableId"
              :disabled="isEdit"
              :default-options="defaultSkuOptions"
              autofocus
              @input="onSelectProduct"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="5">
            <FormulateInput
              id="event_product_sidebar-quantity"
              v-model.number="itemForm.quantity"
              type="number"
              class="required"
              :disabled="isEdit"
              :label="$t('Quantidade reservada')"
              validation="required|min:1"
            />
          </b-col>
        </b-row>

        <b-row v-if="itemForm.id">
          <b-col md="12">
            <b-button
              variant="primary"
              @click="addSkuStock"
            >{{
              $t('Adicionar reserva de estoque')
            }}</b-button>
          </b-col>
          <b-col
            v-if="itemForm.quantityAdded != null"
            md="5"
          >
            <FormulateInput
              id="event_product_sidebar-quantityAdded"
              v-model.number="itemForm.quantityAdded"
              type="number"
              class="required mt-1"
              :label="$t('Quantidade a adicionar')"
              validation="required|min:1"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <div>
              <p class="h6 mb-0">
                {{ $t('Quant. em estoque') }}
              </p>
              <p
                class="h4 text-bold"
                :class="{ 'text-danger': infoValues.stock < 0 }"
              >
                {{ infoValues.stock === 0 ? 0 : infoValues.stock || '-' }}
              </p>
            </div>
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import { BRow, BCol, BButton } from 'bootstrap-vue'
import ESidebar from '@/views/components/ESidebar.vue'
import { authorizationRules, discountTypes, formulateHelper } from '@/mixins'
import { getInitialItemForm } from '@/store/pages/sale/event/event-maintain'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'

const getInitialInfoValues = () => ({
  productPrice: 0,
  subtotal: 0,
  total: 0,
  totalDiscount: 0,
  priceTableDiscountTotal: 0,
  stock: null,
  promotionId: null,
  promotionQuantityAvailable: 0,
  promotionQuantityUnlimited: false,
})

export default {
  components: { BRow, BCol, BButton, ESidebar, ESearchSku },

  mixins: [discountTypes, formulateHelper, authorizationRules],

  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    priceTableId: {
      type: [String, Number],
      default: '',
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      itemForm: getInitialItemForm(),
      infoValues: getInitialInfoValues(),
      productEnriched: getInitialItemForm(),
      delegateUserData: null,
    }
  },

  computed: {
    ...mapState('pages/sale/event/eventMaintain', ['event']),
    isEdit() {
      return !!this.itemForm.id || !!this.itemForm.localId
    },
    defaultSkuOptions() {
      if (this.itemForm.sku) {
        const { id, name } = this.itemForm.sku
        return [
          {
            id, // : String(id),
            value: id, // String(id),
            name,
          },
        ]
      }
      return []
    },
  },

  methods: {
    async show(item) {
      this.cleanSidebar()
      this.showSidebar = true

      if (item) {
        try {
          this.fetching = true
          await new Promise(resolve => setTimeout(() => resolve(), 200))
          this.itemForm = { ...getInitialItemForm(), ...item }
          this.productEnriched = { ...getInitialItemForm(), ...item }
          await this.fetchSkuStockInfo()
          this.updateInfos()
        } finally {
          this.fetching = false
        }
      }
    },

    hide() {
      this.cleanSidebar()
      this.showSidebar = false
    },

    cleanSidebar() {
      this.itemForm = getInitialItemForm()
      this.productEnriched = getInitialItemForm()
      this.infoValues = getInitialInfoValues()
    },

    async onSaveItem() {
      try {
        this.saving = true
        this.$refs.itemSidebarForm.showErrors()
        if (this.$refs.itemSidebarForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }

        if (this.event.id) {
          await this.$http.post(`/api/sales/events/${this.event.id}/add-sku-stock`, {
            eventId: this.event.id,
            skuId: this.itemForm.sku?.id ?? this.itemForm.skuId,
            quantity: this.itemForm.quantityAdded ?? this.itemForm.quantity,
          })
          this.showSuccess({ message: this.$t('Reserva de estoque adicionada com sucesso') })
        }

        if (this.isEdit) {
          this.$emit('update', this.itemForm)
        } else {
          this.$emit('add', { ...this.itemForm, ...this.productEnriched })
        }

        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    async onSelectProduct(productSearched) {
      if (!productSearched) return
      try {
        this.saving = true
        if (!productSearched) return
        const { ean } = productSearched

        const { data } = await this.$http.get(
          `/api/sales/pay-box-sku/store/${this.storeId}/ean/${ean}`,
          { params: { priceTableId: this.priceTableId } }
        )

        if (data?.stock <= 0) {
          await this.confirm({
            title: this.$t('Produto sem estoque'),
            text: this.$t(`Este produto não pode ser adicionado pois está sem estoque.`),
            cancelButtonText: this.$t('Continuar'),
            showCancelButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            focusCancel: true,
          })
          this.cleanSidebar()
          this.focusInput('#event_product_sidebar-sku_product')
          return
        }

        this.focusInput('#event_product_sidebar-quantity')

        const product = {
          skuId: data.skuId,
          sku: { ...data, label: data.name },
          price: data.price,
          promotionId: data.promotionId,
        }
        this.productEnriched = product
        this.updateInfos()
      } catch (error) {
        this.itemForm.sku = null
        this.productEnriched = getInitialItemForm()
        this.infoValues = getInitialInfoValues()
        this.showError({ error })
        this.focusInput('#event_product_sidebar-sku_product', false)
      } finally {
        this.saving = false
      }
    },

    // eslint-disable-next-line func-names
    updateInfos: _.debounce(function () {
      const { stock } = this.productEnriched?.sku || {}

      this.infoValues = {
        stock: stock ?? null,
      }
    }, 400),

    async fetchSkuStockInfo() {
      const ean = this.productEnriched?.sku?.ean
      if (!_.isNil(this.productEnriched?.sku?.stock) || !ean) {
        return
      }
      const { data } = await this.$http.get(
        `/api/sales/pay-box-sku/store/${this.storeId}/ean/${ean}`,
        { params: { priceTableId: this.priceTableId } }
      )

      this.productEnriched.sku.stock = data.stock

      if (this.itemForm.id) {
        const isToRefreshPromotionData =
          this.itemForm.promotionId &&
          data.promotionId &&
          this.itemForm.promotionId === data.promotionId
        if (isToRefreshPromotionData) {
          const promotionData = {
            priceFrom: data.priceFrom,
            promotionQuantityAvailable: data.promotionQuantityAvailable,
            promotionQuantityUnlimited: data.promotionQuantityUnlimited,
          }

          this.itemForm.sku = {
            ...this.itemForm.sku,
            ...promotionData,
          }

          this.productEnriched.sku = {
            ...this.productEnriched.sku,
            ...promotionData,
          }

          this.updateInfos()
        }
      }
    },

    addSkuStock() {
      this.itemForm.quantityAdded = 1
    },
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 800;
}
</style>
